//import "@coreui/icons/css/coreui-icons.min.css";
import 'leaflet/dist/leaflet.css'
import "font-awesome/scss/font-awesome.scss";
import "simple-line-icons/scss/simple-line-icons.scss";
import "flag-icon-css/css/flag-icons.min.css";
import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/scss/style.scss";
import 'vue-tel-input/dist/vue-tel-input.css';
import 'vue-select/dist/vue-select.css';
import Notifications from 'vue-notification'
import {Icon} from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
/*
// CoreUI Icons
import '@coreui/icons/css/free.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'simple-line-icons/css/simple-line-icons.css'

// import VueCompositionApi from '@vue/composition-api';
*/

//import Transition from './components/Transition'
import Vue from 'vue';
import VueTelInput from 'vue-tel-input';
 import ToggleButton from 'vue-js-toggle-button';
import App from './App.vue';
import router from './router';
import i18n from './plugins/i18n';

//  import './transition-polyfill';
import { SidebarNav as CNavbarNav,Header as CHeader,Footer as CFooter } from '@coreui/vue';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import * as rules from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
import fr from "vee-validate/dist/locale/fr.json";
import  {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("en", en);
localize("fr", fr);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(ToggleButton);
Vue.use(VueTelInput);
//Vue.component('Transition', Transition);

Vue.use(Notifications);
// vue-select
import vSelect from 'vue-select';
Vue.component('v-select', vSelect);
Vue.component('CNavbarNav', CNavbarNav);
Vue.component('CHeader', CHeader);
Vue.component('CFooter', CFooter);
// vue-flag-icon
import FlagIcon from 'vue-flag-icon';
Vue.use(FlagIcon);

// Configuration
Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');
