export const HttpMethod = {
   GET: 'GET',
   POST: 'POST',
   PUT: 'PUT',
   DELETE: 'DELETE',
   PATCH: 'PATCH',
   HEAD: 'HEAD',
   OPTIONS: 'OPTIONS',
};

export const ApiFormat = {
   JSON: 'application/json',
   JSON_MERGE_PATCH: 'application/merge-patch+json',
   JSONLD: 'application/ld+json',
   GRAPHQL: 'application/graphql',
   JSONAPI: 'application/vnd.api+json',
   HAL: 'application/hal+json',
   YAML: 'application/x-yaml',
   CSV: 'text/csv',
   HTML: 'text/html',
   RAW_JSON: 'raw/json',
   RAW_XML: 'raw/xml',
};
export const ApiRoutesWithoutPrefix = {
   PRODUCT_LIST_BYLATLONG: '/product/list/byLatLong',
   PRODUCT_LIST_BY_LAT_LONG: '/product/list/byLatLong',
   PRODUCT_LIST_BY_NAME: '/product/list/byName',
   PRODUCT_CATALOGUE_FOR_ADMIN_GET: '/productCatalogue/forAdmin/get',
   PRODUCT_LIST_BY_USER_NAME: '/product/list/byUserName',
   PRODUCT_LIST_BY_PRODUCT_GROUP_ID: '/product/list/ByProductGroupId',
   ADMIN_USER_REGISTER: '/admin/user/register',
   USER_REGISTER: '/user/register',
   USER_PASSWORD_FORGOT: '/user/password/forgot',
   USER_PASSWORD_RESET: '/user/password/reset',
   ADMIN_LOGOUT: '/admin-logout',
   USER_LOGIN: '/user/login',
   LOGIN_USER: '/loginUser',
   USER_PASSWORD_CHANGE: 'user/password/change',
   STATISTICS: '/statistics',
   COMMANDS: '/commands',
   LOGIN: '/login',
   SETTINGS: '/settings',
   FORGET_PASSWORD: '/forget_passwords',
   LOGOUT: '/logout',
   PRODUCT_GROUP_SAVE: '/productGroup/save',
   PRODUCT_GROUP_UPDATE: '/productGroup/update',
   UPDATE_SETTINGS: '/update-settings',
   UPDATE_COMMISSION: '/update-commission',
   GET_CURRENCIES: '/get-currencies',
   UPDATE_CURRENCY: '/update-currency',
   ADD_CURRENCY: '/add-currency',
   DELETE_CURRENCY: '/delete-currency',
   ROLES: '/roles',
   BASKET_PRODUCT_SAVE_PLUSE_WEB: '/basket/product/savepluse/web',
   PRODUCT_LIST_BY_FILTER: '/product/list/byFilter',
   BASKET_PRODUCT_SAVE_PLUSE: '/basket/product/savepluse',
   STORE: '/store',
   PRODUCT_GET_LATLNG: '/Product/get/latlng',
   PRODUCT_LIST_BY_USER_TYPE: `/product/list/byUserType`,
   PRODUCT_CATALOGUE_BY_SEARCH_USER: '/productCatalogue/bysearch/user',
   PRODUCT_GROUPS: '/productGroups',
   USER_STORE_GET: '/user/store/get',
   FORUM_DELETE: '/forum/delete',
   USER_PROFILE: '/user/profile',
   ADMIN_USER_UPDATE: '/admin/user/update',
   USERS_BACKOFFICE: '/users/backoffice',
   USER_BACKOFFICE_PROFILE: '/user/backoffice/profile',
   STORE_CATEGORY: '/store/category',
   DEPARTMENTS: '/departments',
   PRODUCT_GROUP_DELETE: '/productGroup/delete',
   GET_BUYER_SUB_PROFILE: '/get/buyerSubprofile',
   BASKET_ADDRESS_SET: '/basket/address/set',
   USER_ADDRESS_PROFILE: '/user/address/Profile',
   UPDATE_NEW_DELIVERY_ADDRESS: '/update/new/delivery/address',
   SAVE_NEW_DELIVERY_ADDRESS: '/save/new/delivery/address',
   ADVERTISEMENTS_GET_BUY: '/advertisements/get/buy',
   ADVERTISEMENTS_SAVE: '/advertisements/save',
   ADVERTISEMENTS_UPDATE: '/advertisements/update',
   ADVERTISEMENTS_GET_SELL: '/advertisements/get/sell',
   STORE_PRODUCT_GROUPS: '/store/productGroups',
   PRODUCT_LIST: '/product/list',
   USER_DEACTIVATE: '/user/deactivate',
   USER_ACTIVATE: '/user/activate',
   ACCEPT_WEB_REQUEST: '/acceptWebRequest',
   USER_LIST_BY_USER_NAME: `/user/list/byUserName`,
   GET_FRIEND_LIST: '/get/FriendList',
   PRODUCT_GET: '/Product/get',
   IMAGE: '/image',
   IMAGES: '/images',
   FORUM_COMMENT_DELETE: '/forumComment/delete',
   FORUM_COMMENT_SAVE: '/forumComment/save',
   FORUM_COMMENT_UPDATE: '/forumComment/update',
   FORUM_COMMENT_GET: '/forumComment/get',
   FORUM_GET: '/forum/get',
   FORUM_SAVE: '/forum/save',
   FORUM_UPDATE: '/forum/update',
   FORUM_ADMIN_TYPE_GET: '/forum/adminType/get',
   FORUM_GET_BY_NAME: '/forum/get/ByName',
   BASKET_PRODUCT_DELETE: '/basket/product/delete',
   BASKET_GET: '/basket/get',
   USER_PROFILE_PHOTO_REMOVE: '/user/Profile/photo/remove',
   STORE_ENABLE: '/store/enable',
   MANAGER_SAVE: '/manager/save',
   USERS_BACKOFFICE_BY_ID: '/users/backoffice/ById',
   STORE_BY_USER: '/store/byUser',
   OFFERS_SAVE: '/offers/save',
   OFFERS_UPDATE: '/offers/update',
   OFFERS_DELETE: '/offers/delete',
   PRODUCT_CATALOGUE_GROUP_GET: '/productCatalogue/group/get',
   PRODUCT_GROUPS_USER: '/productGroups/user',
   DELETE_PRODUCT_CATALOGUE_IMAGES_DELETE: '/deleteProductCatalogueImages/delete',
   OFFER_GROUP_BY_STORE_USER: '/offer/group/bystore/user',
   OFFER_FOR_ADMIN_GET: '/offer/forAdmin/get',
   PRODUCT_CATALOGUE_DISABLE: '/productCatalogue/disable',
   PRODUCT_CATALOGUE_ENABLE: '/productCatalogue/enable',
   PENDING_OFFER_GET: '/pendingOffer/get',
   OFFER_ENABLE: '/offer/enable',
   ORDER_STORE_STATUS_UPDATE: '/order/store/status/update',
   ORDER_MASTER_STATUS: '/order/master/status',
   ORDERS: '/orders',
   ORDERS_USER_STORE: '/orders/user/store',
   UPDATE_ORDER_PAYMENT_STATUS: '/update/order/payment/status',
   PAYMENTS: '/payments',
   USER_PAYMENT_INFO: '/user/payment/info',
   PAYMENT_SAVE: '/payment/save',
   PAYMENT_UPDATE: '/payment/update',
   PAYMENT_DELETE: '/payment/delete',
   ORDER_SAVE: '/order/save',
   WEB_ORDER_SAVE: `/web/order/save`,
   POST_DELETE: '/post/delete',
   POST_SAVE: '/post/save',
   POST_UPDATE: '/post/update',
   POSTS: '/posts',
   POST_DELETE_DOCUMENTS: '/post/delete/documents',
   USER_CHECK_PRODUCT: '/user/checkProduct',
   PENDING_PRODUCT_CATALOGUE_GROUP_BY_STORE_USER:
      '/pendingProductCatalogue/group/bystore/user',
   PRODUCT_CATALOGUE_GROUP_BY_STORE_USER: '/productCatalogue/group/bystore/user',
   PRODUCT_CATALOGUE_DELETE: '/productCatalogue/delete',
   PRODUCT_CATALOGUE_UPDATE: '/productCatalogue/update',
   PRODUCT_CATALOGUE_SAVE: '/productCatalogue/save',
   STORE_ADV_DELETE: '/store/adv/delete',
   STORE_ALL_ADV_GET_ADV: '/store/all/adv/get/adv',
   STORE_DELETE: '/store/delete',
   STORE_DISABLE: '/store/disable',
   STORE_SAVE: '/store/save',
   STORE_UPDATE: '/store/update',
   USER_DELETE: '/user/delete',
   USERS: '/users',
   PENDING_USERS: '/pending/users',
   STORE_ADV_UPDATE: '/store/adv/update',
   STORE_ADV_SAVE: '/store/adv/save',
   BUYER_SUB_PROFILE_DELETE: '/buyerSubprofile/delete',
};

export const ResponseStatus = {
   SUCCESS: 'Success',
   ERROR: 'Error',
};

export const HttpStatus = {
   CONTINUE: 100,
   SWITCHING_PROTOCOLS: 101,
   PROCESSING: 102, // RFC2518
   EARLY_HINTS: 103, // RFC8297
   OK: 200,
   CREATED: 201,
   ACCEPTED: 202,
   NON_AUTHORITATIVE_INFORMATION: 203,
   NO_CONTENT: 204,
   RESET_CONTENT: 205,
   PARTIAL_CONTENT: 206,
   MULTI_STATUS: 207, // RFC4918
   ALREADY_REPORTED: 208, // RFC5842
   IM_USED: 226, // RFC3229
   MULTIPLE_CHOICES: 300,
   MOVED_PERMANENTLY: 301,
   FOUND: 302,
   SEE_OTHER: 303,
   NOT_MODIFIED: 304,
   USE_PROXY: 305,
   RESERVED: 306,
   TEMPORARY_REDIRECT: 307,
   PERMANENTLY_REDIRECT: 308, // RFC7238
   BAD_REQUEST: 400,
   UNAUTHORIZED: 401,
   PAYMENT_REQUIRED: 402,
   FORBIDDEN: 403,
   NOT_FOUND: 404,
   METHOD_NOT_ALLOWED: 405,
   NOT_ACCEPTABLE: 406,
   PROXY_AUTHENTICATION_REQUIRED: 407,
   REQUEST_TIMEOUT: 408,
   CONFLICT: 409,
   GONE: 410,
   LENGTH_REQUIRED: 411,
   PRECONDITION_FAILED: 412,
   REQUEST_ENTITY_TOO_LARGE: 413,
   REQUEST_URI_TOO_LONG: 414,
   UNSUPPORTED_MEDIA_TYPE: 415,
   REQUESTED_RANGE_NOT_SATISFIABLE: 416,
   EXPECTATION_FAILED: 417,
   I_AM_A_TEAPOT: 418, // RFC2324
   MISDIRECTED_REQUEST: 421, // RFC7540
   UNPROCESSABLE_ENTITY: 422, // RFC4918
   LOCKED: 423, // RFC4918
   FAILED_DEPENDENCY: 424, // RFC4918
   TOO_EARLY: 425, // RFC-ietf-httpbis-replay-04
   UPGRADE_REQUIRED: 426, // RFC2817
   PRECONDITION_REQUIRED: 428, // RFC6585
   TOO_MANY_REQUESTS: 429, // RFC6585
   REQUEST_HEADER_FIELDS_TOO_LARGE: 431, // RFC6585
   UNAVAILABLE_FOR_LEGAL_REASONS: 451, // RFC7725
   INTERNAL_SERVER_ERROR: 500,
   NOT_IMPLEMENTED: 501,
   BAD_GATEWAY: 502,
   SERVICE_UNAVAILABLE: 503,
   GATEWAY_TIMEOUT: 504,
   VERSION_NOT_SUPPORTED: 505,
   VARIANT_ALSO_NEGOTIATES_EXPERIMENTAL: 506, // RFC2295
   INSUFFICIENT_STORAGE: 507, // RFC4918
   LOOP_DETECTED: 508, // RFC5842
   NOT_EXTENDED: 510, // RFC2774
   NETWORK_AUTHENTICATION_REQUIRED: 511, // RFC6585
};

export const UserRoles = {
   SUPER_ADMIN: 1,
   TRADER: 2,
   MANAGER: 3,
   SELLER: 4,
   USER: 5,
   TECHNICIAN: 6,
   RESELLER: 7,
   ADMINISTRATOR: 8,
   CRDE: 9,
   CHAMBRE_DE_COMMERCE: 10,
   CHAMBRE_DE_LAGRICULTURE: 11,
   CRDE_TECHNICIAN: 12,
   CHAMBRE_DE_COMMERCE_TECHNICIAN: 13,
   CHAMBRE_DE_LAGRICULTURE_TECHNICIAN: 14,
   FARMERS: 15,
   SUPPLIERS: 16,
   TRANSFORMERS: 17,
   TRANSPORTER: 18,
};

export const OPENSTREETMAP_SEARCH_URL = 'https://nominatim.openstreetmap.org/search';

export const UserRolesName = {
   SUPER_ADMIN: 'Super Admin',
   TRADER: 'Trader',
   MANAGER: 'Manager',
   SELLER: 'Seller',
   USER: 'User',
   TECHNICIAN: 'Technician',
   RESELLER: 'Reseller',
   ADMINISTRATOR: 'Administrator',
   CRDE: 'CRDE',
   CHAMBRE_DE_COMMERCE: 'Chambre de commerce',
   CHAMBRE_DE_LAGRICULTURE: "Chambre de l'Agriculture",
   CRDE_TECHNICIAN: 'CRDE-Technician',
   CHAMBRE_DE_COMMERCE_TECHNICIAN: 13,
   CHAMBRE_DE_LAGRICULTURE_TECHNICIAN: 14,
   FARMERS: 'Farmers',
   SUPPLIERS: 16,
   TRANSFORMERS: 17,
   TRANSPORTER: 18,
};

export const FirebaseRef = {
   USERS: 'users/',
   FRIEND: 'Friend/',
   CHATS: 'Chats/',
};

export const UserTypes = {
   VENDOR: 'vendor',
   CONSUMER: 'consumer',
   USER: 'user',
   ADMIN: 'admin',
   OPA: 'opa',
   OPERATIVE: 'operative',
   COMPANY: 'company',
   INDIVIDUAL: 'individual',
};
// Define AdminPages as an object
export const Pages = {
   CURRENCIES: '/currencies',
   RESET_PASSWORD: '/reset-password',
   MY_PROFILE: '/my-profile',
   HOME: '/home',
   CART_DETAIL: 'cart-detail',
   CONSUMER: '/consumer',
   VENDOR: '/vendor',
   LOGIN: '/login',
   PAGES_LOGIN: '/pages/login',
   PAGES: '/pages',
   CHAT: '/chat',
   ADMIN: '/admin',
   PAYMENT: '/payment',
   PAYMENT_TYPE: '/payment-type',
   PRODUCT_PAYMENT: '/product-payment',
   FORUMS_MESSAGE: '/forums-message',
   CHANGEPASSWORD_SUCCESSFULLY: '/changepassword-successfully',
   PAGES_CHANGEPASSWORD_SUCCESSFULLY: '/pages/changepassword-successfully',
   FORGOT_PASSWORD: '/forgot-password',
   CHANGE_PASSWORD: '/change-password',
   PAGES_CHANGE_PASSWORD: '/pages/change-password',
   DASHBOARD: '/dashboard',
   STORES: '/stores',
   MANAGERS: '/managers',
   PRODUCTS: '/products',
   PRODUCT_GROUPS: '/product-groups',
   OFFER: '/offer',
   PENDING_PRODUCTS_APPROVAL: '/pending-products-approval',
   PENDING_OFFERS_APPROVAL: '/pending-offers-approval',
   ADS: '/ads',
   USERS: '/users',
   ORDERS: '/orders',
   POSTS: '/posts',
   ALL_PRODUCT: '/all-product',
   STORE_IMAGE: '/store-image',
   PENDING_APPROVAL: '/pending-approval',
   DIRECTORY: '/directory',
   COMMISSION: '/commission',
   BUYER_SUB_PROFILE: '/buyerSubProfile',
   MESSAGE: '/message',
   WEATHER: '/weather',
   FORUMS: '/forums',
   PRODUCT_DESCRIPTION: '/product-description',
   ADDRESS: '/address',
   SIGN_UP: '/sign-up',
   HOME_CHAT: '/home/chat',
   HOME_PRODUCT_DESCRIPTION: '/home/product-description',
};
export const defaultLocale = 'fr';
export const availableLocales = ['en', 'fr'];
export const DATE_FORMAT = {
   LT: 'LT',
   LTS: 'LTS',
   DATE: 'LL',
   DATETIME: 'LLLL',
};
